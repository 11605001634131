<template>
    <el-dialog
        :before-close="closeDialog"
        :show-close="false"
        :visible.sync="dialogVisible"
        center
        width="7.9in">
        <span slot="title" class="title">打印预览</span>
        <div class="dialog-container">
            <div id="printTest">
                <div class="apply-title">发票申请</div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目编号：</span>
                    <span class="approval-detail-content">{{ detail.project.pro_no.join(',') }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所属学会：</span>
                    <span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议名称', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议日期', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
                          <span class="approval-detail-tip">{{ $getTitleByType('大会主席', detail.project.cid) }}：</span
                          ><span class="approval-detail-content">{{ detail.project.chairman }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">申请人：</span>
                    <span class="approval-detail-content">{{ detail.true_name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">上游协议：</span>
                    <span class="approval-detail-content">{{ detail.zanzhu.categories === 1 ? "赞助" : "捐赠" }}（{{
                            detail.zanzhu.corporate_name }}）</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">发票类别：</span>
                    <span class="approval-detail-content">{{ detail.invoice === 1 ? "赞助" : "捐赠" }}</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">发票金额：</span
            ><span class="approval-detail-content">{{
                        parseFloat(Number(detail.amount).toFixed(6))
                    }}万元</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">发票种类：</span
            ><span class="approval-detail-content">{{
                        detail.ticket_type | ticket_type
                    }}</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">发票内容：</span
            ><span class="approval-detail-content"
                >{{ detail.ticket | ticket
                    }}<span v-if="detail.ticket_desc" class="approval-detail-content"
                    >({{ detail.ticket_desc }})</span
                    ></span
                >
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">发票抬头：</span
            ><span class="approval-detail-content">{{
                        detail.invoice_title
                    }}</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">纳税人识别号：</span
            ><span class="approval-detail-content">{{
                        detail.invoice_code
                    }}</span>
                </div>
                <div v-if="detail.invoice_address" class="approval-detail-title">
            <span class="approval-detail-tip">地址：</span
            ><span class="approval-detail-content">{{
                        detail.invoice_address
                    }}</span>
                </div>
                <div v-if="detail.invoice_tel" class="approval-detail-title">
            <span class="approval-detail-tip">电话：</span
            ><span class="approval-detail-content">{{
                        detail.invoice_tel
                    }}</span>
                </div>
                <div v-if="detail.invoice_bank_name" class="approval-detail-title">
            <span class="approval-detail-tip">开户行：</span
            ><span class="approval-detail-content">{{
                        detail.invoice_bank_name
                    }}</span>
                </div>
                <div v-if="detail.invoice_bank_no" class="approval-detail-title">
            <span class="approval-detail-tip">银行账户：</span
            ><span class="approval-detail-content">{{
                        detail.invoice_bank_no
                    }}</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">发票收取方式：</span
            ><span class="approval-detail-content">{{
                        detail.receipt_type == 1 ? "自取" : "邮寄"
                    }}</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">收件人姓名：</span
            ><span class="approval-detail-content">{{
                        detail.receipt_name
                    }}</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">收件人电话：</span
            ><span class="approval-detail-content">{{
                        detail.receipt_tel
                    }}</span>
                </div>
                <div v-if="detail.receipt_type != 1">
                    <div class="approval-detail-title">
              <span class="approval-detail-tip">收件地址：</span
              ><span class="approval-detail-content">{{
                            detail.receipt_addres
                        }}</span>
                    </div>
                </div>
                <div class="approval-detail-container">
                    <div class="approval-detail-title">
                        <!--                        <span class="approval-detail-tip">签名：</span>-->
                        <div class="approval-detail-content">
                            <el-image :src="detail.sign" style="width: 100%; height: auto"></el-image>
                        </div>
                    </div>
                </div>
                <div v-if="detail.receive_time" class="approval-detail-title">
            <span class="approval-detail-tip">取票时间：</span
            ><span class="approval-detail-content">{{
                        detail.receive_time
                    }}</span>
                </div>
                <div v-if="detail.receive_desc" class="approval-detail-title">
<!--                    <span class="approval-detail-tip">取票签名：</span>-->
                    <div class="approval-detail-content">
                        <el-image
                            :src="detail.receive_desc"
                            style="width: 550px; height: auto"
                        ></el-image>
                    </div>
                </div>
                <div class="approval-list-container">
                    <div class="approval-list-tip">审批流程</div>

                    <div class="approval-list">
                        <div class="name-container active">
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i class="el-icon-success"></i>
            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">{{ detail.true_name }}</div>
                                    <div class="info-time">{{ detail.add_time | formatTime }}</div>
                                </div>
                                <div class="info-desc">发起申请</div>
                            </div>
                        </div>
                        <div class="name-container active">
            <span>
               签字
              <i
                  :class="detail.sign ? 'el-icon-success' : 'el-icon-remove'"
              ></i>
            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">
                                        企业负责人
                                    </div>
                                    <div v-if="detail.shenpis[0].sp_time " class="info-time">
                                        {{ detail.shenpis[0].sp_time | formatTime }}
                                    </div>
                                </div>
                                <div class="info-desc">
                                    {{
                                        detail.sign ? "发票信息已确认" : "发票信息确认中"
                                    }}
                                </div>
                            </div>
                        </div>
                        <div v-if="detail.sign" class="name-container active">
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i
                  :class="
                  detail.confirm == 0 ? 'el-icon-remove' : 'el-icon-success'
                "
              ></i>
            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">
                                        {{ detail.true_name }}
                                    </div>
                                    <div v-if="detail.shenpis[1].sp_time " class="info-time">
                                        {{ detail.shenpis[1].sp_time | formatTime }}
                                    </div>

                                </div>
                                <div class="info-desc">
                                    {{ detail.confirm == 0 ? "打印提交中" : "已打印提交" }}
                                </div>
                            </div>
                        </div>

                        <div v-if="detail.confirm != 0">
                            <div v-for="(item,index) in detail.shenpi"
                                 :key="item.id">
                                <div v-if="index === 0 ? true : detail.shenpi[index-1].state === 1"
                                     class="name-container active">
              <span
              >{{
                      item.true_name | sub_name
                  }}
                <i
                    :class="
                    item.state === 0 || item.state === 3
                      ? 'el-icon-remove'
                      : item.state === 1
                      ? 'el-icon-success'
                      : 'el-icon-error'
                  "
                ></i>
              </span>
                                    <div class="info-container">
                                        <div class="info-top">
                                            <div class="info-content">
                                                {{ item.true_name }}
                                                <i v-if="item.state != 0">({{ item.state | sp_state }})</i>
                                            </div>
                                            <div v-if="item.sp_time" class="info-time">
                                                {{ item.sp_time | formatTime }}
                                            </div>
                                        </div>

                                        <div v-if="item.state === 0" class="info-desc">
                                            {{ iself(item) ? "我（" : "" }}
                                            {{ item.state | sp_state }}
                                            {{ iself(item) ? "）" : "" }}
                                        </div>

                                        <div v-if="item.state !== 0" class="info-desc">
                                            <div v-if="item.sp_remark">
                                                <i v-if="item.state ===1 && detail.receipt_type === 2">快递单号：</i>{{ item.sp_remark
                                                }}
                                            </div>
                                            <el-image
                                                v-if="item.ticket_photo"
                                                v-show="item.ticket_photo"
                                                :preview-src-list="[item.ticket_photo]"
                                                :src="item.ticket_photo"
                                                style="width: 150px; height: 150px"
                                            ></el-image>
                                            <div v-if="detail.invoice_amount">
                                                发票金额：{{ parseFloat(Number(detail.invoice_amount).toFixed(6)) }}万元
                                            </div>
                                            <div v-if="detail.invoice_desc">
                                                发票编号：{{ detail.invoice_type | invoice_type }}{{ detail.invoice_desc }}
                                            </div>
                                            <div v-if="detail.invoice_date">
                                                开票日期：{{ detail.invoice_date }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="(detail.state == 1 || detail.shenpi[detail.shenpi.length-1].state === 1) && detail.receipt_type === 1 "
                             class="name-container active">
            <span>
             取票
              <i
                  :class="
                  detail.receive_desc ?'el-icon-success'  :   detail.state === 3 ? 'el-icon-error' : 'el-icon-remove'
                "
              ></i>

            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">
                                        企业负责人
                                    </div>
                                    <div v-if="detail.shenpis[detail.shenpis.length - 2].sp_time " class="info-time">
                                        {{ detail.shenpis[detail.shenpis.length - 2].sp_time | formatTime }}
                                    </div>
                                </div>
                                <div class="info-desc">
                                    {{ detail.receive_desc ? "已取票" : detail.state === 3 ? ('已拒绝：' + detail.shenpis[detail.shenpis.length - 2].sp_remark) : "取票中"
                                    }}
                                </div>
                                <div v-if="detail.receive_time" class="info-desc">取票时间：{{ detail.receive_time }}</div>
                            </div>
                        </div>
                        <div
                            v-if="(detail.state == 1 && detail.receipt_type !== 1) || ( detail.shenpi[detail.shenpi.length-1].state === 1 && detail.receipt_type !== 1) || detail.state == 4 || detail.state == 6 || detail.receive_time"
                            class="name-container active"
                        >
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i
                  :class="
                   detail.state === 3 ? 'el-icon-error' : detail.confirm != 2 ? 'el-icon-remove' : 'el-icon-success'
                "
              ></i>
            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">
                                        {{ detail.true_name }}
                                    </div>
                                    <div v-if="detail.shenpis[detail.shenpis.length - 1].sp_time " class="info-time">
                                        {{ detail.shenpis[detail.shenpis.length - 1].sp_time | formatTime }}
                                    </div>
                                </div>
                                <div class="info-desc">
                                    {{ detail.state === 3 ? ('已拒绝：' + detail.shenpis[detail.shenpis.length - 1].sp_remark) : detail.confirm == 2 ? "确认知晓" : "确认中"
                                    }}
                                </div>
                            </div>
                        </div>

                        <div class="name-container">
                            <span class="iconfont icontongzhi"></span>
                            <div class="info-container">
                                <div class="info-content">抄送人</div>
                                <div class="info-desc">抄送{{ detail.copy.length }}人</div>
                                <div class="copy-members">
                                    <div
                                        v-for="item in detail.copy"
                                        :key="item.id"
                                        class="copy-members-name"
                                    >
                  <span>{{
                          item.true_name | sub_name
                      }}</span>
                                        <div>{{ item.true_name }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取消</el-button>
            <el-button v-print="'#printTest'" class="printing" type="primary">打印</el-button>
      </span>
    </el-dialog>
</template>

<script>
import {sub_name, ZHDate} from "../../../tools";

export default {
    name: "invoice-print",
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        detail: { //详情
            type: Object,
        },
    },
    methods: {
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        closeDialog() {
            this.$emit('watchState', false)
        }
    },
    filters: {
        ticket_type(type) {
            switch (Number(type)) {
                case 1:
                    return "增值税专用发票";
                case 2:
                    return "增值税普通发票";
                default:
                    return "捐赠发票";
            }
        },
        ticket(type) {
            switch (Number(type)) {
                case 1:
                    return "会议费";
                case 2:
                    return "培训费";
                case 3:
                    return "研发服务";
                    case 5:
                    return "项目经费";
                default:
                    return "其他";
            }
        },
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "等待签字确认";
                case 1:
                    return "发票邮寄中";
                case 2:
                    return "已签字,等待打印提交中";
                case 3:
                    return "已驳回";
                case 4:
                    return "企业负责人已取票";
                case 5:
                    return "已撤销";
                case 6:
                    return "已完成";
                case 7:
                    return "财务开票中";
            }
        },
        sp_state(type) {
            switch (Number(type)) {
                case 0:
                    return "开票中";
                case 1:
                    return "已开票";
                case 3:
                    return "未开票";
                default:
                    return "已驳回";
            }
        },
        invoice_type(type) {
            switch (Number(type)) {
                case 1:
                    return "专";
                case 2:
                    return "普";
                case 3:
                    return "捐";
                default:
                    return "";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 16px;
    color: #333333;
    text-align: center;
    font-weight: bold;
}

.dialog-container {
    height: 600px;
    overflow-y: scroll;
    border: 1px solid #e1e1e1;

    &::-webkit-scrollbar {
        display: none;
    }
}

#printTest {
    background: #FFFFFF;
    padding: 20px;
    border: 1px solid #e1e1e1;
}

.apply-title {
    font-size: 22px;
    color: #333333;
    text-align: center;
    font-weight: bold;
    margin: 20px 0 50px;
}

.approval-detail-title {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    color: #999999;
    width: 100%;
    padding: 10px 0;

    .approval-detail-tip {
        flex: 1;
        flex-shrink: 0;

        &.red {
            color: red;
        }
    }

    .approval-detail-tip-length {
        flex-shrink: 0;

        &.red {
            color: red;
        }
    }

    .approval-detail-content {
        flex: 4;
        flex-shrink: 1;
        color: #333333;

        &.red {
            color: red;
        }
    }
}
.approval-list-container {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;

    .approval-list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .name-container {
            position: relative;
            display: flex;
            padding-bottom: 50px;
            flex-direction: row;

            &.active {
                &:before {
                    position: absolute;
                    background: #d3d4d6;
                    width: 1px;
                    top: 0px;
                    bottom: 0px;
                    left: 28px;
                    content: "";
                }
            }

            .info-container {
                flex-grow: 1;
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .info-top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .info-content {
                        font-size: 20px;
                        color: #333333;

                        i {
                            font-size: 14px;
                        }
                    }

                    .info-time {
                        font-size: 16px;
                        color: #999999;
                    }
                }

                .info-desc {
                    margin-top: 2px;
                    font-size: 16px;
                    color: #999999;
                }

                .copy-members {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-top: 20px;

                    .copy-members-name {
                        margin-right: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }

            span {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 56px;
                height: 56px;
                border-radius: 56px;
                border: 1px solid #3b77e7;
                text-align: center;
                /*line-height: 24px;*/
                font-size: 16px;
                color: #ffffff;
                background-color: #3b77e7;
                position: relative;

                i {
                    position: absolute;
                    bottom: -5px;
                    right: -5px;
                    font-size: 18px;
                    width: 18px;
                    height: 18px;
                    overflow: hidden;

                    &.el-icon-remove {
                        color: #ee9900;
                    }

                    &.el-icon-success {
                        color: #13ce66;
                    }

                    &.el-icon-error {
                        color: #ff0000;
                    }
                }
            }
        }
    }

    .approval-list-tip {
        font-size: 18px;
        color: #333333;
        flex-shrink: 1;
        margin-top: 12px;
    }
}
</style>
<template>
    <div class="page-box"  v-if="detail">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>发票申请</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="approval-detail-info">
            <el-button v-if="detail.sign" class="printing" type="primary" @click="printDialogVisible = true">
                打印
            </el-button>
            <div class="approval-detail-container">
                <div class="name-container">
                    <span>{{ detail.true_name | sub_name }}</span>
                    <div class="state-container">
                        <div class="content">{{ detail.true_name
                            }}<i>(已开票{{ detail.fapiao_count
                                }}次,</i><i :class="{red:detail.shenpi_num>1}">第{{ detail.shenpi_num }}次提交)</i>
                        </div>
                        <div v-if="detail.state == 1 && detail.receipt_type === 1" class="state">企业负责人取票中</div>
                        <div v-else class="state">{{ detail.state | state }}</div>
                    </div>
                </div>
                <!--          <div class="approval-detail-title">-->
                <!--            <span class="approval-detail-tip">审批编号：</span-->
                <!--            ><span class="approval-detail-content">{{ detail.shenpi_no }}</span>-->
                <!--          </div>-->
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目编号：</span>
                    <span class="approval-detail-content" v-if="detail">{{ detail.project.pro_no.join(',') }}</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">所属学会：</span
            ><span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">{{ $getTitleByType('会议名称', detail.project.cid) }}：</span
            ><span class="approval-detail-content">{{
                        detail.project.name
                    }}</span>
                </div>
                <div class="approval-detail-title">
          <span class="approval-detail-tip">{{ $getTitleByType('会议日期', detail.project.cid) }}：</span
          ><span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
          <span class="approval-detail-tip">{{ $getTitleByType('大会主席', detail.project.cid) }}：</span
          ><span class="approval-detail-content">{{ detail.project.chairman }}</span>
                </div>
                <project-info :cid="detail.project.cid" :info="detail.project_attach"></project-info>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">上游协议：</span
            ><span class="approval-detail-content"
                >{{ detail.zanzhu.categories === 1 ? "赞助" : "捐赠" }}（{{
                        detail.zanzhu.corporate_name
                    }}）</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">发票类别：</span
            ><span class="approval-detail-content">{{
                        detail.invoice === 1 ? "赞助" : "捐赠"
                    }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">二维码：</span>
                    <div class="approval-detail-content">
                        <el-image
                            :src="detail.qr_code"
                            style="width: 150px; height: 150px"
                        ></el-image>
                    </div>
                </div>
            </div>
            <div v-if="detail.sign" class="approval-detail-container">
                <div class="approval-list-tip">发票信息</div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">发票金额：</span
            ><span class="approval-detail-content">{{
                        parseFloat(Number(detail.amount).toFixed(6))
                    }}万元</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">发票种类：</span
            ><span class="approval-detail-content">{{
                        detail.ticket_type | ticket_type
                    }}</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">发票格式：</span
            ><span class="approval-detail-content">{{
                        detail.fapiao_type | fapiao_type
                    }}</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">备注：</span
            ><span class="approval-detail-content">{{
                        detail.remark
                    }}</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">发票内容：</span
            ><span class="approval-detail-content"
                >{{ detail.ticket | ticket
                    }}<span v-if="detail.ticket_desc" class="approval-detail-content"
                    >({{ detail.ticket_desc }})</span
                    ></span
                >
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">发票抬头：</span
            ><span class="approval-detail-content">{{
                        detail.invoice_title
                    }}</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">纳税人识别号：</span
            ><span class="approval-detail-content">{{
                        detail.invoice_code
                    }}</span>
                </div>
                <div v-if="detail.invoice_address" class="approval-detail-title">
            <span class="approval-detail-tip">地址：</span
            ><span class="approval-detail-content">{{
                        detail.invoice_address
                    }}</span>
                </div>
                <div v-if="detail.invoice_tel" class="approval-detail-title">
            <span class="approval-detail-tip">电话：</span
            ><span class="approval-detail-content">{{
                        detail.invoice_tel
                    }}</span>
                </div>
                <div v-if="detail.invoice_bank_name" class="approval-detail-title">
            <span class="approval-detail-tip">开户行：</span
            ><span class="approval-detail-content">{{
                        detail.invoice_bank_name
                    }}</span>
                </div>
                <div v-if="detail.invoice_bank_no" class="approval-detail-title">
            <span class="approval-detail-tip">银行账户：</span
            ><span class="approval-detail-content">{{
                        detail.invoice_bank_no
                    }}</span>
                </div>
<!--                <div class="approval-detail-title">-->
<!--            <span class="approval-detail-tip">发票收取方式：</span-->
<!--            ><span class="approval-detail-content">{{-->
<!--                        detail.receipt_type == 1 ? "自取" : "邮寄"-->
<!--                    }}</span>-->
<!--                </div>-->
                <div class="approval-detail-title">
            <span class="approval-detail-tip">联系人姓名：</span
            ><span class="approval-detail-content">{{
                        detail.receipt_name
                    }}</span>
                </div>
                <div class="approval-detail-title">
            <span class="approval-detail-tip">联系人电话：</span
            ><span class="approval-detail-content">{{
                        detail.receipt_tel
                    }}</span>
                </div>
                <div v-if="detail.receipt_type != 1">
                    <div class="approval-detail-title">
              <span class="approval-detail-tip">联系人邮箱：</span
              ><span class="approval-detail-content">{{
                            detail.receipt_addres
                        }}</span>
                    </div>
                </div>
                <div v-if="detail.sign" class="approval-detail-title">
                    <span class="approval-detail-tip">签名：</span>
                    <div class="approval-detail-content">
                        <el-image
                            :src="detail.sign"
                            style="width: 550px; height: auto"
                        ></el-image>
                    </div>
                </div>
                <div v-if="detail.receive_qrcode && detail.shenpi[0].state == 1" class="approval-detail-title">
                    <span class="approval-detail-tip">取票二维码：</span>
                    <div class="approval-detail-content">
                        <el-image
                            :src="detail.receive_qrcode"
                            style="width: 150px; height: 150px"
                        ></el-image>
                    </div>
                </div>
                <div v-if="detail.receive_time" class="approval-detail-title">
            <span class="approval-detail-tip">取票时间：</span
            ><span class="approval-detail-content">{{
                        detail.receive_time
                    }}</span>
                </div>
                <div v-if="detail.receive_desc" class="approval-detail-title">
                    <span class="approval-detail-tip">取票签名：</span>
                    <div class="approval-detail-content">
                        <el-image
                            :src="detail.receive_desc"
                            style="width: 550px; height: auto"
                        ></el-image>
                    </div>
                </div>
            </div>
            <div class="approval-list-container">
                <div class="approval-list-tip">审批流程</div>

                <div class="approval-list">
                    <div class="name-container active">
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i class="el-icon-success"></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div class="info-time">{{ detail.add_time | formatTime }}</div>
                            </div>
                            <div class="info-desc">发起申请</div>
                        </div>
                    </div>
                    <div class="name-container active">
            <span>
               签字
              <i
                  :class="detail.sign ? 'el-icon-success' : 'el-icon-remove'"
              ></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">
                                    企业负责人
                                </div>
                                <div v-if="detail.shenpis[0].sp_time " class="info-time">
                                    {{ detail.shenpis[0].sp_time | formatTime }}
                                </div>
                            </div>
                            <div class="info-desc">
                                {{
                                    detail.sign ? "发票信息已确认" : "发票信息确认中"
                                }}
                            </div>
                        </div>
                    </div>
                    <div v-if="detail.sign" class="name-container active">
            <span>
              {{
                    detail.true_name | sub_name
                }}
              <i
                  :class="
                  detail.confirm == 0 ? 'el-icon-remove' : 'el-icon-success'
                "
              ></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">
                                    {{ detail.true_name }}
                                </div>
                                <div v-if="detail.shenpis[1].sp_time " class="info-time">
                                    {{ detail.shenpis[1].sp_time | formatTime }}
                                </div>

                            </div>
                            <div class="info-desc">
                                {{ detail.confirm == 0 ? "打印提交中" : "已打印提交" }}
                            </div>
                        </div>
                    </div>

<!--                    <div v-if="detail.confirm != 0">-->
<!--                        <div v-for="(item,index) in detail.shenpi"-->
<!--                             :key="item.id">-->
<!--                            <div v-if="index === 0 ? true : detail.shenpi[index-1].state === 1"-->
<!--                                 class="name-container active">-->
<!--              <span-->
<!--              >{{-->
<!--                      item.true_name | sub_name-->
<!--                  }}-->
<!--                <i-->
<!--                    :class="-->
<!--                    item.state === 0 || item.state === 3-->
<!--                      ? 'el-icon-remove'-->
<!--                      : item.state === 1-->
<!--                      ? 'el-icon-success'-->
<!--                      : 'el-icon-error'-->
<!--                  "-->
<!--                ></i>-->
<!--              </span>-->
<!--                                <div class="info-container">-->
<!--                                    <div class="info-top">-->
<!--                                        <div class="info-content">-->
<!--                                            {{ item.true_name }}-->
<!--                                            <i v-if="item.state != 0">({{ item.state | sp_state }})</i>-->
<!--                                        </div>-->
<!--                                        <div v-if="item.sp_time" class="info-time">-->
<!--                                            {{ item.sp_time | formatTime }}-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div v-if="item.state === 0" class="info-desc">-->
<!--                                        {{ iself(item) ? "我（" : "" }}-->
<!--                                        {{ item.state | sp_state }}-->
<!--                                        {{ iself(item) ? "）" : "" }}-->
<!--                                    </div>-->

<!--                                    <div v-if="item.state !== 0" class="info-desc">-->
<!--                                        <div v-if="item.sp_remark">-->
<!--                                            <i v-if="item.state ===1 && detail.receipt_type === 2">快递单号：</i>{{ item.sp_remark-->
<!--                                            }}-->
<!--                                        </div>-->
<!--                                        <el-image-->
<!--                                            v-if="item.ticket_photo"-->
<!--                                            v-show="item.ticket_photo"-->
<!--                                            :preview-src-list="[item.ticket_photo]"-->
<!--                                            :src="item.ticket_photo"-->
<!--                                            style="width: 150px; height: 150px"-->
<!--                                        ></el-image>-->
<!--                                        <div v-if="detail.invoice_amount">-->
<!--                                            发票金额：{{ parseFloat(Number(detail.invoice_amount).toFixed(6)) }}万元-->
<!--                                        </div>-->
<!--                                        <div v-if="detail.invoice_desc">-->
<!--                                            发票编号：{{ detail.invoice_type | invoice_type }}{{ detail.invoice_desc }}-->
<!--                                        </div>-->
<!--                                        <div v-if="detail.invoice_date">-->
<!--                                            开票日期：{{ detail.invoice_date }}-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div v-if="(detail.state == 1 || detail.shenpi[detail.shenpi.length-1].state === 1) && detail.receipt_type === 1 "-->
<!--                         class="name-container active">-->
<!--            <span>-->
<!--             取票-->
<!--              <i-->
<!--                  :class="-->
<!--                  detail.receive_desc ?'el-icon-success'  :   detail.state === 3 ? 'el-icon-error' : 'el-icon-remove'-->
<!--                "-->
<!--              ></i>-->

<!--            </span>-->
<!--                        <div class="info-container">-->
<!--                            <div class="info-top">-->
<!--                                <div class="info-content">-->
<!--                                    企业负责人-->
<!--                                </div>-->
<!--                                <div v-if="detail.shenpis[detail.shenpis.length - 2].sp_time " class="info-time">-->
<!--                                    {{ detail.shenpis[detail.shenpis.length - 2].sp_time | formatTime }}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="info-desc">-->
<!--                                {{ detail.receive_desc ? "已取票" : detail.state === 3 ? ('已拒绝：' + detail.shenpis[detail.shenpis.length - 2].sp_remark) : "取票中"-->
<!--                                }}-->
<!--                            </div>-->
<!--                            <div v-if="detail.receive_time" class="info-desc">取票时间：{{ detail.receive_time }}</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div-->
<!--                        v-if="(detail.state == 1 && detail.receipt_type !== 1) || ( detail.shenpi[detail.shenpi.length-1].state === 1 && detail.receipt_type !== 1) || detail.state == 4 || detail.state == 6 || detail.receive_time"-->
<!--                        class="name-container active"-->
<!--                    >   -->
                        <div
                        v-if="detail.state == 3 || detail.state == 4 || detail.state == 6 || detail.state == 7"
                        class="name-container active"
                    >
            <span>
              {{
                    detail.shenpi[detail.shenpi.length-1].true_name | sub_name
                }}
              <i
                  :class="
                   detail.shenpi[detail.shenpi.length-1].state === 2 ? 'el-icon-error' : detail.shenpi[detail.shenpi.length-1].state != 1 ? 'el-icon-remove' : 'el-icon-success'
                "
              ></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">
                                    {{ detail.shenpi[detail.shenpi.length-1].true_name }}
                                </div>
                                <div v-if="detail.shenpis[detail.shenpis.length - 1].sp_time " class="info-time">
                                    {{ detail.shenpis[detail.shenpis.length - 1].sp_time | formatTime }}
                                </div>
                            </div>
                            <div class="info-desc">
                                {{ detail.shenpi[detail.shenpi.length-1].state === 2 ? ('已拒绝：' + detail.shenpis[detail.shenpis.length - 1].sp_remark) : detail.shenpi[detail.shenpi.length-1].state == 1 ? "确认知晓" : "确认中"
                                }}
                            </div>
                        </div>
                    </div>

                    <div class="name-container">
                        <span class="iconfont icontongzhi"></span>
                        <div class="info-container">
                            <div class="info-content">抄送人</div>
                            <div class="info-desc">抄送{{ detail.copy.length }}人</div>
                            <div class="copy-members">
                                <div
                                    v-for="item in detail.copy"
                                    :key="item.id"
                                    class="copy-members-name">
                                     <span>{{item.true_name | sub_name}}</span>
                                    <div>{{ item.true_name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="approval-btn">
                <el-button
                    v-if="member_type == 1 &&(detail.state == 0)"
                    class="approval-btn-left"
                    @click="toRevoke()">
                    撤回
                </el-button>
                <el-button
                    v-if="(member_type == 1 && detail.state == 2) ||
                     (member_type == 1 && ((detail.state == 1 && detail.receipt_type !== 1) || (detail.state == 4 && detail.receipt_type === 1))) ||
                     (member_type == 0 && isApprovaler)" class="approval-btn-left"
                    @click="shenpi_rejectedVisible = true">驳回
                </el-button>
                <el-button
                    v-if="member_type == 1 && detail.state == 2"
                    :disabled="requestState"
                    :loading="requestState" class="approval-btn-right"
                    @click="invoiceConfirm(1)">
                    提交
                </el-button>

                <el-button
                    v-if="member_type == 1 && ((detail.state == 1 && detail.receipt_type !== 1) || (detail.state == 4 && detail.receipt_type === 1))"
                    class="approval-btn-left"
                    @click="editSendVisible = true">
                    拒绝
                </el-button>
                <el-button
                    v-if="member_type == 1 && ((detail.state == 1 && detail.receipt_type !== 1) || (detail.state == 4 && detail.receipt_type === 1))"
                    :disabled="requestState" :loading="requestState"
                    class="approval-btn-right"
                    @click="invoiceConfirm(2)">
                    确认
                </el-button>
                <router-link :to="'/home/invoice?id=' + pid" v-if="member_type == 1 && detail.state == 3">
                    <el-button class="approval-btn-left">重新提交
                    </el-button>
                </router-link>

                <el-button
                    v-if="member_type == 0 && isApprovaler"
                    class="approval-btn-left"
                    @click="showDialog(2)">拒绝
                </el-button>
                <el-button
                    v-if="member_type == 0 && isApprovaler"
                    class="approval-btn-right"
                    @click="showDialog(1)">通过
                </el-button>
            </div>
        </div>
        <div v-if="detail.history.length > 0" class="history-container">
            <div class="history-title">历史记录</div>
            <div class="history-list">
                <router-link
                    v-for="(item, index) in detail.history"
                    :key="index"
                    :to="'/home/invoiceDetail?cctype=1&id=' + item.id + '&type=' + member_type"
                    class="history-item"
                >
                    <div class="item-content">{{ index + 1 }}.{{ item.desc }}</div>
                    <div class="item-time">{{ item.add_time }}</div>
                </router-link>
            </div>
        </div>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="editSendVisible"
            center
            width="30%"
        >
      <span slot="title" class="dialog-title">
              拒绝原因
          </span>
            <div class="dialog-container flex-center">
                <el-input
                    v-model="remark"
                    :rows="5"
                    placeholder="请输入审批拒绝原因"
                    resize="none"
                    type="textarea"
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button :disabled.sync="requestState" :loading.sync="requestState" type="primary" @click="toPublishApproval()">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="editVisible"
            center
            width="30%"
        >
      <span slot="title" class="dialog-title">{{
              state === 1 ? "审批同意确认"
                  : "审批拒绝原因"
          }}</span>
            <el-input
                v-model="remark"
                :placeholder=" state === 1 ? '请输入审批同意意见' : '请输入审批拒绝原因'  "
                :rows="5"
                resize="none"
                type="textarea"
            ></el-input>
<!--            <div v-if="state === 1 && detail.receipt_type === 2" class="dialog-container ">-->
<!--                <div class="input-item">-->
<!--                    <div>发票快递单号：</div>-->
<!--                    <el-input v-model="remark" placeholder="请填写发票快递单号"></el-input>-->
<!--                </div>-->
<!--                <div class="input-item">-->
<!--                    <div>发票快递单号照片：</div>-->
<!--                    <el-upload-->
<!--                        :action="uploadPath"-->
<!--                        :before-upload="beforeAvatarUpload"-->
<!--                        :data="QiniuData"-->
<!--                        :multiple="false"-->
<!--                        :on-error="uploadError"-->
<!--                        :on-success="uploadSuccess"-->
<!--                        :show-file-list="false"-->
<!--                        accept="image/*"-->
<!--                        class="avatar-uploader">-->
<!--                        <img v-if="ticket_photo" :src="ticket_photo" class="avatar"/>-->
<!--                        <div v-else class="el-icon-plus avatar-uploader-icon"></div>-->
<!--                    </el-upload>-->
<!--                </div>-->
<!--                <div class="input-item">-->
<!--                    <div>发票金额：</div>-->
<!--                    <el-input v-model="invoice_amount" placeholder="请填写发票金额"></el-input>-->
<!--                    <div>万元</div>-->
<!--                </div>-->
<!--                <div class="input-item">-->
<!--                    <div>发票编号：</div>-->
<!--                    <el-select-->
<!--                        v-model="invoice_type"-->
<!--                        placeholder="类型">-->
<!--                        <el-option-->
<!--                            v-for="item in typeOptions"-->
<!--                            :key="item.value"-->
<!--                            :label="item.label"-->
<!--                            :value="item.value"-->
<!--                        >-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                    <el-input v-model="invoice_desc" placeholder="请填写发票编号"></el-input>-->
<!--                </div>-->
<!--                <div class="input-item">-->
<!--                    <div>开票日期：</div>-->
<!--                    <el-date-picker-->
<!--                        v-model="invoice_date"-->
<!--                        format="yyyy-MM-dd"-->
<!--                        placeholder="选择日期"-->
<!--                        type="date"-->
<!--                        value-format="yyyy-MM-dd">-->
<!--                    </el-date-picker>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div v-else class="dialog-container flex-center around-box">-->
<!--                <el-input-->
<!--                    v-model="remark"-->
<!--                    :placeholder=" state === 1? detail.receipt_type === 1 ? '请输入审批同意意见'  : '请填写发票快递单号'  : '请输入审批拒绝原因'  "-->
<!--                    :rows="5"-->
<!--                    resize="none"-->
<!--                    type="textarea"-->
<!--                ></el-input>-->
<!--                <div v-if="state === 1" class="input-item">-->
<!--                    <div>发票金额：</div>-->
<!--                    <el-input v-model="invoice_amount" placeholder="请填写发票金额"></el-input>-->
<!--                    <div>万元</div>-->
<!--                </div>-->
<!--                <div v-if="state === 1" class="input-item">-->
<!--                    <div>发票编号：</div>-->
<!--                    <el-select-->
<!--                        v-model="invoice_type"-->
<!--                        placeholder="类型">-->
<!--                        <el-option-->
<!--                            v-for="item in typeOptions"-->
<!--                            :key="item.value"-->
<!--                            :label="item.label"-->
<!--                            :value="item.value"-->
<!--                        >-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                    <el-input v-model="invoice_desc" placeholder="请填写发票编号"></el-input>-->
<!--                </div>-->
<!--                <div v-if="state === 1" class="input-item">-->
<!--                    <div>开票日期：</div>-->
<!--                    <el-date-picker-->
<!--                        v-model="invoice_date"-->
<!--                        format="yyyy-MM-dd"-->
<!--                        placeholder="选择日期"-->
<!--                        type="date"-->
<!--                        value-format="yyyy-MM-dd">-->
<!--                    </el-date-picker>-->
<!--                </div>-->
<!--            </div>-->
            <span slot="footer" class="dialog-footer">
        <el-button :disabled.sync="requestState" :loading.sync="requestState" type="primary" @click="toApproval()">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog
            :before-close="closeDialog"
                   :visible.sync="shenpi_rejectedVisible"
                   center width="30%">
            <span slot="title" class="dialog-title">审批驳回</span>
            <div class="dialog-container flex-center">
                <el-input v-model="remark" placeholder="请输入审批驳回原因"
                          :rows="5" resize="none" type="textarea"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button :disabled="requestState" :loading="requestState" type="primary"
                           @click="shenpi_rejected()">确定</el-button>
              </span>
        </el-dialog>



        <el-tooltip placement="top" effect="light">
            <div slot="content">
                <div v-for="(item,index) in detail.shenpi_rejected" :key="index">
                    {{ item.true_name }}{{ item.create_time | formatTime }}驳回了审批，驳回原因为：{{ item.message }}<br/>
                </div>
            </div>
            <div class="record flex align-center justify-center" v-if="detail.shenpi_rejected && detail.shenpi_rejected.length">
                驳回<br>记录
            </div>
        </el-tooltip>
        <invoice-print :detail="detail" :dialog-visible="printDialogVisible" @watchState="watchState"></invoice-print>

    </div>
</template>

<script>
import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
import ElImage from "../../../node_modules/element-ui/packages/image/src/main.vue";
import config from "../../config/index";
import {genUpToken, qiniuaddr, uploadPath} from "../../tools/CryptoJS";
import {getPathIndex, sub_name, ZHDate} from "../../tools";
import InvoicePrint from "./print/invoice";
import ProjectInfo from "../components/ProjectInfo";

export default {
    components: {
        ProjectInfo,
        InvoicePrint,
        ElImage,
        ElButton,
    },
    name: "invoiceDetail",
    data() {
        return {
            pid: "",
            member_type: -1,
            isApprovaler: false,
            editVisible: false,
            editSendVisible: false,
            shenpi_rejectedVisible: false,
            requestState: false,
            state: -1,
            detail: '',
            typeOptions: [{value: 1, label: "专",}, {value: 2, label: "普",}, {value: 3, label: "捐",}],
            remark: "",
            ticket_photo: "",
            invoice_amount: "",
            invoice_type: "",
            invoice_desc: "",
            invoice_date: "",
            QiniuData: {
                fname: "", //  文件名
                key: "", //文件名字处理
                token: "", //七牛云token
            },
            qiniuaddr: qiniuaddr, // 七牛云的图片外链地址 你的七牛云里配置有
            uploadPath: uploadPath, // 七牛云的图片外链地址 你的七牛云里配置有
            printDialogVisible: false
        };
    },
    created() {
        let token = genUpToken();
        this.QiniuData.token = token;
        this.$emit("setIndex", [0, getPathIndex(this.$route.query.type)]);
        this.init();
    },
    methods: {
        watchState() {
            this.printDialogVisible = false
        },
        showDialog(type) {
            this.editVisible = true;
            this.state = type;
            this.remark =
                this.state === 1 && this.detail.receipt_type === 1 ? "同意" : "";
            if (this.state === 1) {
                this.invoice_amount = parseFloat(Number(this.detail.amount).toFixed(6))
                this.invoice_date = this.$tools.ZHDate(new Date())
            }
        },
        closeDialog() {
            this.remark = ''
            this.shenpi_rejectedVisible = false;
            this.editVisible = false;
            this.editSendVisible = false;
            this.state = -1;
        },
        toApproval() {
            if (this.state === 1) {
//                    if (!this.remark && !this.ticket_photo) {
//                        this.$message.error("请填写发票快递单号或上传照片");
//                        return;
//                    }

            } else {
                if (!this.remark) {
                    this.$message.error(
                        this.state === 1
                                ? "请输入审批同意意见"
                            : "请输入审批拒绝原因"
                    );
                    return;
                }
            }
//             if (this.state === 1) {
//                 if (!this.invoice_amount) {
//                     this.$message.error("请填写发票金额");
//                     return;
//                 }
// //                    if (!this.invoice_type) {
// //                        this.$message.error("请选择发票类型");
// //                        return;
// //                    }
//                 if (!this.invoice_date) {
//                     this.$message.error("请选择开票日期");
//                     return;
//                 }
//                 if (parseFloat(Number(this.invoice_amount).toFixed(6)) !== parseFloat(Number(this.detail.zanzhu.amount).toFixed(6))) {
//                     let that = this;
//                     that
//                         .$confirm("发票金额填写与合同金额不一致", "温馨提示", {
//                             confirmButtonText: "知晓",
//                             cancelButtonText: "取消",
//                             type: "warning",
//                         })
//                         .then(() => {
//                             that.submitApproval()
//                         })
//                         .catch(() => {
//                         });
//                 } else {
//                     this.submitApproval()
//                 }
//             } else {
                this.submitApproval()
            // }

        },
        submitApproval() {
            this.requestState = true
            this.$api.apiContent
                .shenpi({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 5, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    state: this.state,
                    remark: this.remark,
                    ticket_photo: this.ticket_photo,
                    invoice_amount: this.invoice_amount,
                    invoice_type: this.invoice_type,
                    invoice_desc: this.invoice_desc,
                    invoice_date: this.invoice_date,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 5, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                            state: this.state,
                            remark: this.remark,
                            ticket_photo: this.ticket_photo,
                            invoice_amount: this.invoice_amount,
                            invoice_type: this.invoice_type,
                            invoice_desc: this.invoice_desc,
                            invoice_date: this.invoice_date,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        toPublishApproval() {
            if (!this.remark) {
                this.$message.error("请输入拒绝原因");
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .shenpi({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 5, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    state: 2,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 5, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                            state: 2,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        shenpi_rejected() {
            if (!this.remark) return this.$message.error("请输入驳回原因")
            this.requestState = true
            this.$api.apiContent
                .shenpi_rejected({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 5, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    state: 2,
                    id: this.pid,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 5, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            state: 2,
                            id: this.pid,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        toRevoke() {
            this.$api.apiContent
                .revoke({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 5, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 5, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.backPage();
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        invoiceConfirm(type) {
            this.requestState = true
            this.$api.apiContent
                .invoiceConfirm({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    type: type,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            type: type,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        init() {
            this.detail = ''
            this.pid = this.$route.query.id;
            this.member_type = this.$route.query.type;
            this.$api.apiContent
                .getFapiao({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    type: this.$route.query.cctype ? this.$route.query.cctype : "",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            type: this.$route.query.cctype ? this.$route.query.cctype : "",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.history.map((item) => {
                        item.desc = item.desc.replace(/<br>/g, " ");
                        item.add_time = this.$tools.ZHDate(
                            new Date(item.add_time * 1000),
                            1
                        );
                    });
                    res.data.shenpis = res.data.shenpi
                    res.data.shenpi = res.data.shenpi.filter(item => {
                        return !item.is_user || item.is_user === 2
                    })
                    res.data.time =
                        this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) +
                        " 至 " +
                        this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                    res.data.qr_code = config.urlImgStr + res.data.qr_code;
                    res.data.sign = res.data.sign ? config.urlImgStr + res.data.sign : "";
                    res.data.receive_desc = res.data.receive_desc
                        ? config.urlImgStr + res.data.receive_desc
                        : "";
                    res.data.receive_time = res.data.receive_time
                        ? this.$tools.ZHDate(new Date(res.data.receive_time * 1000), 1)
                        : "";
                    res.data.receive_qrcode = res.data.receive_qrcode
                        ? config.urlImgStr + res.data.receive_qrcode
                        : "";
                    this.invoice_type = res.data.ticket_type
                    this.detail = res.data;
                    this.isApproval();
                })
                .catch((e) => {
                    console.log(e)
                    this.detail = ''
                });
        },
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        isApproval: function () {
            this.isApprovaler = false;
            this.editVisible = false;
            this.shenpi_rejectedVisible = false;
            this.editSendVisible = false;
            this.requestState = false
            this.detail.shenpi.map((item, index) => {
                if (item.user_id === this.$store.state.user.mid && item.state === 0) {
                    if (index === 0) {
                        this.isApprovaler = this.detail.state === 7
                    } else {
                        if (this.detail.shenpi[index - 1].state === 1) {
                            this.isApprovaler = true;
                        }
                    }
                }
            });
        },
        //  上传成功
        uploadSuccess(response, file, fileList) {
            console.log(file);
            console.log(fileList);
            this.ticket_photo = `${this.qiniuaddr}/${response.key}`;
            //在这里你就可以获取到上传到七牛的外链URL了
            this.loading.close();
        },
        //  上传失败
        uploadError() {
            this.loading.close();
            this.$message({
                message: "上传出错，请重试！",
                type: "error",
                center: true,
            });
        },
        beforeAvatarUpload(file) {
            //这里的key给加上了时间戳，目的是为了防止上传冲突
            this.QiniuData.fname = file.name;
            this.QiniuData.key = `${new Date().getTime()}${file.name}`;
            this.loading = this.$loading({
                lock: true,
                text: "上传中，请稍后",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
        },
        backPage() {
            this.$tools.goBack();
        },
    },
    filters: {
        ticket_type(type) {
            switch (Number(type)) {
                case 1:
                    return "增值税专用发票";
                case 2:
                    return "增值税普通发票";
                default:
                    return "捐赠发票";
            }
        },
        fapiao_type(type) {
            // switch (Number(type)) {
            //     case 1:
            //         return "OFD";
            //     case 2:
            //         return "PDF";
            //     case 3:
            //         return "XML";
            //     default:
            //         return "";
            // }
            if (!type) return ''
            let arr = ["OFD", "PDF", "XML"]
            let str = []
            let types = `${type}`.split(',')
            types.map(item => {
                str.push(arr[Number(item) - 1])
            })
            return str.join(',')
        },
        ticket(type) {
            switch (Number(type)) {
                case 1:
                    return "会议费";
                case 2:
                    return "培训费";
                case 3:
                    return "研发服务";
                case 5:
                    return "项目经费";
                default:
                    return "其他";
            }
        },
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "等待签字确认";
                case 1:
                    return "发票邮寄中";
                case 2:
                    return "已签字,等待打印提交中";
                case 3:
                    return "已驳回";
                case 4:
                    return "企业负责人已取票";
                case 5:
                    return "已撤销";
                case 6:
                    return "已完成";
                case 7:
                    return "财务开票中";
            }
        },
        sp_state(type) {
            switch (Number(type)) {
                case 0:
                    return "开票中";
                case 1:
                    return "已开票";
                case 3:
                    return "未开票";
                default:
                    return "已驳回";
            }
        },
        invoice_type(type) {
            switch (Number(type)) {
                case 1:
                    return "专";
                case 2:
                    return "普";
                case 3:
                    return "捐";
                default:
                    return "";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
    computed: {
        isSend: function () {
            return this.detail.user_id === this.$store.state.user.mid;
        },
    },
    watch: {
        $route: "init",
    },
};
</script>

<style lang="scss" scoped>
.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
    align-self: center;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.page-box {
    .dialog-title {
        color: #000;
        font-size: 24px;
    }

    .dialog-footer {
        .el-button {
            width: 235px;
        }
    }

    .dialog-container {
        display: flex;
        flex-direction: column;

        .input-item {
            display: flex;
            align-items: center;
            margin-top: 20px;

            div {
                flex-shrink: 0;
            }

            .el-select {
                width: 100px;
            }

            .el-input {
                width: auto;
                flex-grow: 1;
            }
        }
    }

    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .approval-detail-info {
        position: relative;
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        min-height: 600px;
        padding-bottom: 100px;

        .printing {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .approval-btn {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 50%;
            position: absolute;
            bottom: 60px;
            padding-left: 120px;

            .approval-btn-left {
                width: 180px;
                background: transparent;
                border: 1px solid #3b77e7;
                color: #3b77e7;
            }

            .approval-btn-right {
                width: 180px;
                margin-left: 20px;
                background: #3b77e7;
                color: #ffffff;
            }
        }

        .approval-list-container {
            display: flex;
            flex-direction: row;
            padding: 40px 80px;

            .approval-list {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin-left: 12px;

                .name-container {
                    position: relative;
                    flex-grow: 1;
                    display: flex;
                    padding-bottom: 50px;
                    flex-direction: row;

                    &.active {
                        &:before {
                            position: absolute;
                            background: #d3d4d6;
                            width: 1px;
                            top: 0px;
                            bottom: 0px;
                            left: 28px;
                            content: "";
                        }
                    }

                    .info-container {
                        flex-grow: 1;
                        margin-left: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .info-top {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .info-content {
                                font-size: 20px;
                                color: #333333;

                                i {
                                    font-size: 14px;
                                }
                            }

                            .info-time {
                                font-size: 16px;
                                color: #999999;
                            }
                        }

                        .info-desc {
                            margin-top: 2px;
                            font-size: 16px;
                            color: #999999;
                        }

                        .copy-members {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: 20px;

                            .copy-members-name {
                                margin-right: 10px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        border: 1px solid #3b77e7;
                        text-align: center;
                        /*line-height: 24px;*/
                        font-size: 16px;
                        color: #ffffff;
                        background-color: #3b77e7;
                        position: relative;

                        i {
                            position: absolute;
                            bottom: -5px;
                            right: -5px;
                            font-size: 18px;
                            width: 18px;
                            height: 18px;
                            overflow: hidden;

                            &.el-icon-remove {
                                color: #ee9900;
                            }

                            &.el-icon-success {
                                color: #13ce66;
                            }

                            &.el-icon-error {
                                color: #ff0000;
                            }
                        }
                    }
                }
            }

            .approval-list-tip {
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
                margin-top: 12px;
            }
        }

        .approval-detail-container {
            padding: 40px;
            border-bottom: 1px solid #f0f2f5;

            .approval-list-tip {
                margin-left: 40px;
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
            }

            .approval-detail-title {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                color: #999999;
                width: 100%;

                .approval-detail-tip {
                    flex: 1;
                    flex-shrink: 0;
                    text-align: right;
                }

                .approval-detail-content {
                    flex: 7;
                    flex-shrink: 1;
                    color: #333333;
                }
            }

            .name-container {
                display: flex;
                flex-direction: row;
                padding-left: 40px;

                .state-container {
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .content {
                        font-size: 22px;
                        color: #333333;

                        .red {
                            color: red;
                        }
                    }

                    .state {
                        font-size: 16px;
                        margin-top: 6px;
                        color: #13ce66;
                    }
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 65px;
                    height: 65px;
                    border-radius: 65px;
                    text-align: center;
                    cursor: pointer;
                    /*line-height: 24px;*/
                    font-size: 20px;
                    color: #fff;
                    background-color: #3b77e7;
                }
            }
        }
    }
}
</style>
